import React, { useState, useCallback } from "react";
import { Grid, Input, Dropdown, Label, Popup, Button } from "semantic-ui-react";
import { addressAutocomplete } from "./apicalls.js";

const Egon = ({ objSend, setObjSend }) => {
  const [data, setData] = useState({});
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion ] = useState("");
  const [address, setAddress] = useState("");
  const [cap, setCap] = useState("");
  const [civico, setCivico] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [unlock, setUnlock] = useState(false);

  const onCitySearchChange = async (event) => {
    const input = event.target.value;
    const resp = await addressAutocomplete(data, input, "city");
    const temp = [];
    for (let i = 0; i < resp.length; i++) {
      temp.push({
        key: i,
        text: resp[i].c,
        value: resp[i].c,
      });
    }
    setCities(temp);
  };
  
  const onCityChange = async (event, element) => {
    setCity(element.value);
    setCities([
      {
        key: element.value,
        text: element.value,
        value: element.value,
      },
    ]);
    const resp = await addressAutocomplete(data, element.value, "city");
    const temp = [];
    for (let i = 0; i < resp.length; i++) {
      temp.push({
        key: i,
        text: resp[i].p,
        value: resp[i].p,
      });
    }
    setProvinces(temp);
    setProvince(resp[0].p);
    setRegion(resp[0].r);
    //setProvince_acr(resp[0].x)
    
    setData({
      ...data,
      ...{ city: element.value, province: resp[0].p, province_acr: resp[0].x, region:resp[0].r },
    });

    setObjSend({
      ...objSend,
      ...{ citta: element.value, pr: resp[0].x, region:resp[0].r },
    });

    setAddress("");
    setAddresses([]);
    setCap("");
  };

  const onProvinceSearchChange = async (event) => {
    const input = event.target.value;
    const resp = await addressAutocomplete(data, input, "province");
    const temp = [];
    for (let i = 0; i < resp.length; i++) {
      temp.push({
        key: i,
        text: resp[i].p,
        value: resp[i].p,
        region: resp[i].r
      });
    }
    setProvinces(temp);
  };


  const onCivicChange = (event) => {
    const input = event.target.value;
    setCivico(input);
    setObjSend({ ...objSend, ...{ civico: input } });
  };

  const onProvinceChange = (event, element) => {
    if (element.value !== "") {
      const selectedOption = element.options.find(item => item.value === element.value)
      setProvince(element.value);
      setRegion(selectedOption.region)
      setObjSend({ ...objSend, province: element.value });
      setData({ ...data, ...{ province: element.value } });
    }

    setCity("");
    setCities([]);
    setAddress("");
    setAddresses([]);
    setCap("");

  };

  const onAddressSearchChange = async (event) => {
    const input = event.target.value;
    const resp = await addressAutocomplete(data, input, "address");
    const temp = [];
    for (let i = 0; i < resp.length; i++) {
      temp.push({
        key: i,
        text: resp[i].s,
        value: resp[i].s,
      });
    }
    setAddresses(temp);
  };

  const onAddressChange = async (event, element) => {
    setAddress(element.value);
    const resp = await addressAutocomplete(data, element.value, "address");
    setCap(resp[0].z);
    setData({ ...data, ...{ address: element.value, cap: resp[0].z } });
    setObjSend({ ...objSend, ...{ indir: element.value, cap: resp[0].z } });
  };


  const onCapChange = async (event) => {
    if (event.target.value <= 99999) {
      setCap(event.target.value);
      setData({ ...data, ...{ cap: event.target.value } });
      setObjSend({ ...objSend, ...{ cap: event.target.value } });
    }
  };

  const onResetClick = (event) => {
    setData({});
    setObjSend({});
    setCity("");
    setCities([]);
    setAddress("");
    setAddresses([]);
    setCap("");
    setCivico("");
    setProvince("");
    setProvinces([]);
    setRegion("")
  };

  const onUnlockClick = (boolean) => {
    setUnlock(boolean);
  };

  const onInputChange = useCallback(
    (event, par) => {
      if (par === "citta") {
        setCity(event.target.value)
      } else if (par === "pr") {
        setProvince(event.target.value)
      } else if (par === "indir") {
        setAddress(event.target.value)
      } else if (par === "region") {
        setRegion(event.target.value)
      } 

      setObjSend({ ...objSend, ...{ [par]: event.target.value } });
    },
    [objSend, setObjSend]
  );

 

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          <Label>Città *</Label>
          {(unlock && (
              <Input
              fluid
              value={city}
              type="string"
              onChange={(event) => onInputChange(event, "citta")}
              placeholder="Inserisci la città"
            />
          )) || (
            <Dropdown
              placeholder="Inserisci la città"
              fluid
              search
              selection
              value={city}
              options={cities}
              onSearchChange={onCitySearchChange}
              onChange={onCityChange}
            />
          )}
        </Grid.Column>
        <Grid.Column width={4}>
          <Label>Provincia *</Label>

          {(unlock && (
             <Input
             fluid
             value={province}
             type="string"
             onChange={(event) => onInputChange(event, "pr")}
             placeholder="Inserisci la provincia"
           />
          )) || (
            <Dropdown
              placeholder="Inserisci la provincia"
              fluid
              search
              selection
              value={province}
              options={provinces}
              onSearchChange={onProvinceSearchChange}
              onChange={onProvinceChange}
            />
          )}
        </Grid.Column>
        <Grid.Column width={4}>
          <Label>Regione</Label>
          {(unlock && (
             <Input
             fluid
             value={region}
             type="string"
             onChange={(event) => onInputChange(event, "region")}
             placeholder="Inserisci la regione"
           />
          )) || (
            <Input
            placeholder="Inserisci la regione"
            fluid
            value={region}
            />
          )}
        </Grid.Column>
        <Grid.Column width={4}>
          <Label>Cap *</Label>

          <Input
              value={cap}
              type="number"
              fluid
              max="99999"
              onChange={onCapChange}
              placeholder="CAP"
            />
        </Grid.Column>
       
      </Grid.Row>

      <Grid.Row>
      <Grid.Column width={4}>
          <Label>Via *</Label>
          {(unlock && (
             <Input
             fluid
             value={address}
             type="string"
             onChange={(event) => onInputChange(event, "indir")}
             placeholder="Inserisci la via"
           />
          )) || (
            <Dropdown
              placeholder="Inserisci la via"
              fluid
              search
              selection
              value={address}
              options={addresses}
              onSearchChange={onAddressSearchChange}
              onChange={onAddressChange}
            />
          )}
        </Grid.Column>
       
        <Grid.Column width={4}>
          <Label>Civico *</Label>
          <Input
            fluid
            value={civico}
            type="number"
            max="99999"
            onChange={onCivicChange}
            placeholder="Inserisci il civico"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={10}></Grid.Column>

        <Grid.Column width={3}>
          <Popup
            trigger={
              <Button
                onClick={() => {
                  onUnlockClick(!unlock); 
                  setData([])
                  if(!unlock) {
                    setCity("")
                    setAddress("")
                    setProvince("")
                    setCivico("")
                    setCap("")
                    setRegion("")
                  }
                }}
                fluid
                positive
                content={unlock ? "BLOCCA": "SBLOCCA"}
                icon={unlock ? "lock": "unlock"}
              />
            }
            content={unlock ? "Blocca i campi di input dall'autocompilazione": "Sblocca i campi di input dall'autocompilazione"}
          />
        </Grid.Column>

        <Grid.Column width={3}>
          <Popup
            trigger={
              <Button
                onClick={onResetClick}
                fluid
                color="red"
                content="RESET"
                icon="refresh"
              />
            }
            content="Resetta i campi indirizzo"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Egon;